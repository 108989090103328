import './src/styles/global.css';

const loader = document.getElementById("loader-wrapper")!;

export const onClientEntry = () => {
    setTimeout(() => {
      if (loader) {
        console.log("state1")
        loader.classList.add("flex");
      }
    }, 0);
  };
  
  export const onInitialClientRender = () => {
    setTimeout(() => {
      if (loader) {
        console.log("state2")
        loader.classList.add("flex");
      }
    }, 0);
  };
  
  export const onRouteUpdate = () => {
    setTimeout(() => {
      if (loader) {
        console.log("state3")
        loader.classList.add("hidden");
      }
    }, 3500);
  };
  